import PropTypes from "prop-types";
import React, { useState } from "react";
import { ReactComponent as Hamburger } from "../icons/Hamburger.svg";
import styles from "./Header.scss";
export function MobileNav({ isHmc }) {
  const [navOpen, setNav] = useState(false);
  const toggleNav = () => {
    setNav(!navOpen);
  };
  const cloud = isHmc ? null : "cloud";

  return (
    <>
      <div className={styles.navContainer}>
        <div className={styles.mobileNavWrapper}>
          <Hamburger onClick={toggleNav} />
          <header className={`${navOpen ? `is-active ${cloud}` : "hide"}`}>
            <nav role="navigation">
              <ul>
                <li>
                  <a href="https://gatestokyo.co.jp/" target="_blank" rel="noopener noreferrer">
                    Company
                  </a>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
}

MobileNav.propTypes = {
  showDocsLink: PropTypes.bool,
  docsUrl: PropTypes.string,
  showSourceLink: PropTypes.bool,
  showCommunityLink: PropTypes.bool,
  communityUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  isHmc: PropTypes.bool
};
