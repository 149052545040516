import PropTypes from "prop-types";
import React from "react";
import { CreateRoomButton } from "../home/CreateRoomButton";
import { SocialBar } from "../home/SocialBar";
import { AppLogo } from "../misc/AppLogo";
import { Container } from "./Container";
import styles from "./Header.scss";

export function Header({ isSignedIn, isHmc }) {
  return (
    <header>
      <Container as="div" className={styles.container}>
        <nav>
          <ul>
            <li>
              <a href="/" className={styles.homeLink}>
                <AppLogo />
              </a>
            </li>
          </ul>
        </nav>
        <div className={styles.signIn}>
          {isSignedIn && <CreateRoomButton />}
          <a
            href="https://gatestokyo.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
            className="tw-btn tw-btn-ghost tw-btn-sm md:tw-btn-md lg:tw-btn-lg"
          >
            Company
          </a>
        </div>
        {isHmc ? <SocialBar mobile /> : null}
      </Container>
    </header>
  );
}

Header.propTypes = {
  showCloud: PropTypes.bool,
  enableSpoke: PropTypes.bool,
  editorName: PropTypes.string,
  showDocsLink: PropTypes.bool,
  docsUrl: PropTypes.string,
  showSourceLink: PropTypes.bool,
  showCommunityLink: PropTypes.bool,
  communityUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  email: PropTypes.string,
  onSignOut: PropTypes.func,
  isHmc: PropTypes.bool
};
