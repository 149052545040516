/* eslint-disable @calm/react-intl/missing-formatted-message */
import React, { useEffect } from "react";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { Container } from "../layout/Container";
import { PageContainer } from "../layout/PageContainer";
import styles from "./HomePage.scss";

export function HomePage() {
  const breakpoint = useCssBreakpoints();
  const barHeight = breakpoint === "sm" ? 4 : 6;

  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      qs.delete("new");
      createAndRedirectToNewHub(null, null, true, qs);
    }
  }, []);

  return (
    <PageContainer className={styles.homePage}>
      <Container>
        <div className="tw-w-full tw-grid tw-justify-items-center tw-gap-6 tw-mt-16 tw-px-4">
          <div className="tw-text-3xl md:tw-text-6xl tw-font-bold tw-relative tw-z-0">
            GATES WORLD<span className="tw-absolute tw-left-full"></span>
          </div>
          <svg
            width="100%"
            height={barHeight}
            viewBox={`0 0 450 ${barHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="450" height={barHeight} rx={barHeight / 2} fill="#00C8EA" />
          </svg>
          {/* <div className="tw-text-xs md:tw-text-lg tw-text-base-content/60 tw-font-bold">
            バーチャルイベントや3D展示会に。貴社のバーチャル空間を作成いたします。
          </div> */}
        </div>
      </Container>
      {/* {sortedPublicRooms.length > 0 && (
        <Container className={styles.roomsContainer}>
          <Column grow padding className={styles.rooms}>
            <MediaGrid center>
              {sortedPublicRooms.map(room => {
                return (
                  <MediaTile
                    key={room.id}
                    entry={room}
                    processThumbnailUrl={(entry, width, height) =>
                      scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                    }
                  />
                );
              })}
            </MediaGrid>
          </Column>
        </Container>
      )} */}
      {/* {sortedFavoriteRooms.length > 0 && (
        <Container className={styles.roomsContainer}>
          <Column grow padding className={styles.rooms}>
            <MediaGrid center>
              {sortedFavoriteRooms.map(room => {
                return (
                  <MediaTile
                    key={room.id}
                    entry={room}
                    processThumbnailUrl={(entry, width, height) =>
                      scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                    }
                  />
                );
              })}
            </MediaGrid>
          </Column>
        </Container>
      )} */}
    </PageContainer>
  );
}
